import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Button,
  FormControlLabel,
  Switch,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Chip,
  LinearProgress,
} from "@material-ui/core";
import { captureException } from "@sentry/minimal";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { MdCheck, MdExpandMore } from "react-icons/md";
import styled, { css } from "styled-components";
import { DateTimePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useLessonApplicants } from "../hooks/useLessonApplications";
import { useSnackbar } from "../hooks/useSnackbar";
import { StyledTableHeaderCell, TestResult } from "./Lessons";
import { SectionHeading } from "./Season";
import { Body, Heading5 } from "./Typography";
import { lv } from "date-fns/locale";

const StyledAccordionDetails = styled(AccordionDetails)`
  display: flex;
  flex-wrap: wrap;
`;

export const ScrollableAccordionDetails = styled(StyledAccordionDetails)`
  max-height: 400px;
  overflow-y: auto;
`;

export const StyledAccordion = styled(Accordion)`
  width: 100%;
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  ${({ $unsaved }) =>
    $unsaved &&
    css`
      background-color: rgb(226, 68, 0, 0.1);
    `}
`;

const StyledSummaryList = styled(Box)`
  max-height: 300px;
  overflow-y: auto;
  flex-grow: 1;
`;

const SummaryWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 15px;
  justify-content: space-between;
`;

export const IdChip = styled(Chip)`
  margin-right: 10px;
  font-size: 10px;
`;

const StyledTable = styled(Table)`
  position: absolute;
`;

const TableWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 400px;
`;

const InvisibleSymbol = styled.span`
  color: transparent;
  position: absolute;
`;

const SummaryList = ({ heading, list }) => {
  return (
    <StyledSummaryList>
      <Heading5>{heading}</Heading5>
      <List dense={true}>
        {list.map(([item, count]) => (
          <ListItem key={item}>
            <ListItemText primary={item} />
            <ListItemSecondaryAction>{count}</ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </StyledSummaryList>
  );
};

const schoolYearToText = (schoolYear) =>
  schoolYear === "teacher"
    ? "Skolotājs(-a)"
    : schoolYear === "other"
    ? "Cits"
    : `${schoolYear}. klase`;

const ApplicantsSection = ({ lessonApplicants, isReady, lessonId }) => {
  const [search, setSearch] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  if (!isReady) {
    return null;
  }

  const filteredLessonApplicants = lessonApplicants.filter(({ id, school }) => {
    if (!search.length) {
      return true;
    }
    return (
      id.toLowerCase().includes(search.toLowerCase()) ||
      school.name.toLowerCase().includes(search.toLowerCase())
    );
  });

  const onEmailsCopy = async () => {
    try {
      await navigator.clipboard.writeText(
        filteredLessonApplicants.map(({ id }) => id).join(",")
      );
      enqueueSnackbar("Epasti nokopēti", { variant: "success" });
    } catch (e) {
      captureException(e);
      enqueueSnackbar("Kaut kas nogāja greizi!", { variant: "error" });
    }
  };

  return (
    <StyledAccordion defaultExpanded={false}>
      <AccordionSummary expandIcon={<MdExpandMore />}>
        <SectionHeading>Dalībnieki ({lessonApplicants.length})</SectionHeading>
      </AccordionSummary>
      <ScrollableAccordionDetails>
        <SummaryWrapper>
          <TextField
            placeholder="Meklēt"
            label="Meklēt"
            variant="outlined"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Button variant="contained" onClick={() => onEmailsCopy()}>
            Kopēt E-pastus (CSV)
          </Button>
        </SummaryWrapper>
        <TableWrapper>
          <StyledTable>
            <TableHead>
              <TableRow>
                <StyledTableHeaderCell>Vārds, Uzvārds</StyledTableHeaderCell>
                <StyledTableHeaderCell>E-pasts</StyledTableHeaderCell>
                <StyledTableHeaderCell>Skola</StyledTableHeaderCell>
                <StyledTableHeaderCell>Klase</StyledTableHeaderCell>
                <StyledTableHeaderCell align="center">
                  Ieradies
                </StyledTableHeaderCell>
                <StyledTableHeaderCell align="center">
                  Testa rezultāts
                </StyledTableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredLessonApplicants.map(
                ({
                  id,
                  fullName,
                  school,
                  schoolYear,
                  quizScores,
                  lessonAttendance,
                }) => {
                  const hasScore = !new Set([null, undefined]).has(
                    quizScores?.[lessonId]?.score
                  );
                  const hasAttended = new Set(lessonAttendance || []).has(
                    lessonId
                  );
                  return (
                    <TableRow key={id}>
                      <TableCell>{fullName}</TableCell>
                      <TableCell>{id}</TableCell>
                      <TableCell>{school.name}</TableCell>
                      <TableCell>
                        {!!schoolYear && schoolYearToText(schoolYear)}
                      </TableCell>
                      <TableCell align="center">
                        {hasAttended && (
                          <>
                            <MdCheck />
                            <InvisibleSymbol>x</InvisibleSymbol>
                          </>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {hasScore ? (
                          <TestResult score={quizScores?.[lessonId]?.score} />
                        ) : (
                          "-"
                        )}
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </StyledTable>
        </TableWrapper>
      </ScrollableAccordionDetails>
    </StyledAccordion>
  );
};

const StatsSection = ({ lessonApplicants }) => {
  const { countBySchool, countBySchoolYear } = lessonApplicants.reduce(
    (
      { countBySchool, countBySchoolYear },
      { school: { name }, schoolYear }
    ) => {
      return {
        countBySchool: {
          ...countBySchool,
          [name]: (countBySchool[name] || 0) + 1,
        },
        countBySchoolYear: {
          ...countBySchoolYear,
          [schoolYearToText(schoolYear)]:
            (countBySchoolYear[schoolYearToText(schoolYear)] || 0) + 1,
        },
      };
    },
    { countBySchool: {}, countBySchoolYear: {} }
  );

  return (
    <StyledAccordion defaultExpanded={false}>
      <AccordionSummary expandIcon={<MdExpandMore />}>
        <SectionHeading>Statistika</SectionHeading>
      </AccordionSummary>
      <ScrollableAccordionDetails>
        <SummaryWrapper>
          <SummaryList
            heading="Skaits pa skolām"
            list={Object.entries(countBySchool).sort(
              ([_a, a], [_b, b]) => b - a
            )}
          />
          <SummaryList
            heading="Skaits pa klašu grupām"
            list={Object.entries(countBySchoolYear).sort(
              ([_a, a], [_b, b]) => b - a
            )}
          />
        </SummaryWrapper>
      </ScrollableAccordionDetails>
    </StyledAccordion>
  );
};

export const StyledLessonForm = styled.form`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  width: 100%;
`;

export const LessonFormRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  @media screen and (min-width: 900px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
  }
  .MuiFormControl-root {
    margin-bottom: 10px;
  }
`;

export const LessonFormFirstRow = styled(LessonFormRow)`
  @media screen and (min-width: 900px) {
    grid-template-columns: 75px 1fr 1fr;
  }
`;

export const ButtonsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > * {
    margin: 0 5px;
  }
`;

const QRCode = styled.img`
  width: 100px;
  height: 100px;
`;

const isValidHttpUrl = (string) => {
  try {
    const url = new URL(string);
    return url.protocol === "http:" || url.protocol === "https:";
  } catch (_) {
    return false;
  }
};

const getFormId = (formUrl) => {
  if (!formUrl) {
    return null;
  }
  const pattern = /https:\/\/docs\.google\.com\/forms\/d\/e\/(.*)\/viewform.*/;
  const match = formUrl.match(pattern);
  return match?.[1] || null;
};

const LessonForm = ({ lesson, saveLesson, deleteLesson, isBusy }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({ defaultValues: lesson });
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const joinCode = watch("joinCode");
  const QRUrl = `https://api.qrserver.com/v1/create-qr-code/?size=1500x1500&data=https://jfs.lu.lv/apmeklejums?code=${joinCode}`;
  const onSubmit = (lesson) => {
    const {
      id,
      name,
      date,
      isPublic,
      includedInSC,
      joinCode,
      applicationsOpen,
      questionnaireUrl,
      questionnaireOpen,
      quizUrl,
      quizOpen,
    } = lesson;
    saveLesson(id, {
      name,
      date,
      isPublic: !!isPublic,
      includedInSC: !!includedInSC,
      joinCode,
      applicationsOpen: !!applicationsOpen,
      questionnaireUrl,
      questionnaireOpen: !!questionnaireOpen,
      quizUrl: quizUrl ? decodeURIComponent(quizUrl) : null,
      quizId: getFormId(quizUrl),
      quizOpen: !!quizOpen,
    });
  };

  const onDelete = () => {
    deleteLesson(lesson.id);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={lv}>
      <StyledLessonForm>
        <LessonFormFirstRow>
          <TextField
            label="ID"
            variant="outlined"
            disabled={!lesson.unsaved}
            error={!!errors.id}
            helperText={!!errors.id && "ID ir obligāts un tam jābūt unikālam!"}
            {...register("id", { required: true })}
          />
          <TextField
            label="Nosaukums/Tēma"
            variant="outlined"
            error={!!errors.name}
            helperText={!!errors.name && "Nosaukums ir obligāts!"}
            {...register("name", { required: true })}
          />
          <Controller
            control={control}
            name="date"
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <DateTimePicker
                label="Datums/Laiks"
                inputVariant="outlined"
                value={value}
                onChange={onChange}
              />
            )}
          />
        </LessonFormFirstRow>
        <LessonFormRow>
          <FormControlLabel
            control={
              <Controller
                name="applicationsOpen"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Switch
                    checked={!!value}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                )}
              />
            }
            label="Pieteikšanās atvērta"
          />
          <FormControlLabel
            control={
              <Controller
                name="isPublic"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Switch
                    checked={!!value}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                )}
              />
            }
            label="Publisks"
          />
          <FormControlLabel
            control={
              <Controller
                name="includedInSC"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Switch
                    checked={!!value}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                )}
              />
            }
            label="SK ieskaite"
          />
        </LessonFormRow>
        <LessonFormRow>
          <TextField
            label="Anketas URL"
            variant="outlined"
            error={!!errors.questionnaireUrl}
            helperText={!!errors.questionnaireUrl && "Ievadi korektu URL!"}
            {...register("questionnaireUrl", {
              validate: (val) => !val || isValidHttpUrl(val),
            })}
          />
          <FormControlLabel
            control={
              <Controller
                name="questionnaireOpen"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Switch
                    checked={!!value}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                )}
              />
            }
            label="Anketa atvērta"
          />
        </LessonFormRow>
        <LessonFormRow>
          <TextField
            label="Testa URL"
            variant="outlined"
            error={!!errors.quizUrl}
            helperText={
              !!errors.quizUrl
                ? "Ievadi korektu URL!"
                : "Ja URL būs sastopams fragments <EMAIL>, tas tiks aizvietots ar dalībnieka e-pastu."
            }
            {...register("quizUrl", {
              validate: (val) =>
                !val || (isValidHttpUrl(val) && !!getFormId(val)),
            })}
          />
          <FormControlLabel
            control={
              <Controller
                name="quizOpen"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Switch
                    checked={!!value}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                )}
              />
            }
            label="Tests atvērts"
          />
        </LessonFormRow>
        <LessonFormRow>
          <TextField
            label="Pievienošanās kods"
            variant="outlined"
            disabled={true}
            {...register("joinCode")}
          />
          <a href={QRUrl} target="_blank" rel="noreferrer">
            <QRCode src={QRUrl} />
          </a>
        </LessonFormRow>
        <ButtonsRow>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSubmit(onSubmit)}
          >
            Saglabāt
          </Button>
          <Button
            variant="contained"
            onClick={() => setShowDeleteConfirm(true)}
          >
            Dzēst
          </Button>
          {isBusy(lesson.id) && <CircularProgress size={36} />}
        </ButtonsRow>
        <Dialog
          open={showDeleteConfirm}
          onClose={() => setShowDeleteConfirm(false)}
        >
          <DialogTitle>{"Tiešām dzēst nodarbību?"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Šī darbība ir neatgriezeniska!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowDeleteConfirm(false)} color="primary">
              Atcelt
            </Button>
            <Button
              onClick={() => {
                onDelete();
                setShowDeleteConfirm(false);
              }}
              color="secondary"
            >
              Dzēst
            </Button>
          </DialogActions>
        </Dialog>
      </StyledLessonForm>
    </MuiPickersUtilsProvider>
  );
};

const LessonDetails = ({ lessonId }) => {
  const { lessonApplicants, isReady } = useLessonApplicants(lessonId);

  if (!isReady) {
    return <LinearProgress />;
  }

  return (
    <>
      <StatsSection lessonApplicants={lessonApplicants} />
      <ApplicantsSection
        lessonApplicants={lessonApplicants}
        isReady={isReady}
        lessonId={lessonId}
      />
    </>
  );
};

export const AdminLesson = ({ lesson, saveLesson, deleteLesson, isBusy }) => {
  const { id, name, unsaved } = lesson;
  const [expanded, setExpanded] = useState(!!unsaved);

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <StyledAccordionSummary expandIcon={<MdExpandMore />} $unsaved={unsaved}>
        <IdChip label={id} size="small" variant="outlined" />
        <Body>{name}</Body>
      </StyledAccordionSummary>
      {expanded && (
        <StyledAccordionDetails>
          <LessonForm
            lesson={lesson}
            saveLesson={saveLesson}
            deleteLesson={deleteLesson}
            isBusy={isBusy}
          />
          <LessonDetails lessonId={id} />
        </StyledAccordionDetails>
      )}
    </Accordion>
  );
};
