import {
  AccordionDetails,
  AccordionSummary,
  TextField,
  LinearProgress,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Paper,
  IconButton,
  CircularProgress,
  Button,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import React, { useState } from "react";
import { MdAdd, MdDelete, MdExpandMore, MdSave } from "react-icons/md";
import styled from "styled-components";
import { useSchoolCup } from "../hooks/useSchoolCup";
import { StyledAccordion } from "./AdminLesson";
import { TeamMembers } from "./SchoolCupTeam";
import { SectionHeading } from "./Season";
import { Body } from "./Typography";

const ScorePanel = styled(Paper)`
  width: 100%;
`;

const StyledTeamDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ScoreTextField = styled(TextField)`
  margin-right: 20px;
`;

const TeamAccordion = styled(StyledAccordion)`
  overflow-x: auto;
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  flex-wrap: wrap;
`;

const AddScoreRow = ({ saveScore }) => {
  const [[_key, _score], setScore] = useState(["", ""]);

  const onSave = (key, score) => {
    saveScore(key, score);
    setScore(["", ""]);
  };

  return (
    <ListItem>
      <ScoreTextField
        value={_key}
        onChange={(e) => setScore(([_, score]) => [e.target.value, score])}
        variant="outlined"
        size="small"
        label="Atslēga"
      />
      <ScoreTextField
        value={_score}
        onChange={(e) => setScore(([key, _]) => [key, e.target.value])}
        variant="outlined"
        size="small"
        label="Punkti"
        type="number"
      />
      <ListItemSecondaryAction>
        <IconButton
          title="Pievienot"
          onClick={() => onSave(_key, _score)}
          disabled={
            !!(
              !_key ||
              !_score.trim().length ||
              Number.isNaN(_score.trim()) ||
              isNaN(_score.trim())
            )
          }
        >
          <MdAdd />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const ScoreRow = ({ scoreKey, score, saveScore, deleteScore }) => {
  const [[_key, _score], setScore] = useState([scoreKey, score]);
  return (
    <ListItem>
      <ScoreTextField
        value={_key}
        onChange={(e) => setScore(([_, score]) => [e.target.value, score])}
        variant="outlined"
        size="small"
        label="Atslēga"
        disabled={!!(scoreKey && score)}
      />
      <ScoreTextField
        value={_score}
        onChange={(e) => setScore(([key, _]) => [key, e.target.value])}
        variant="outlined"
        size="small"
        label="Punkti"
      />
      <ListItemSecondaryAction>
        <IconButton title="Saglabāt" onClick={() => saveScore(_key, _score)}>
          <MdSave />
        </IconButton>
        <IconButton
          color="secondary"
          title="Dzēst"
          onClick={() => deleteScore(_key)}
        >
          <MdDelete />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const TeamResults = ({ results, saveScore }) => {
  const { testScores = {}, extraScores = {} } = results;

  const onSave = (key, score) => {
    saveScore({ ...extraScores, [key]: parseInt(score, 10) });
  };

  const onDelete = (key) => {
    const { [key]: _, ...newExtraScores } = extraScores;
    saveScore(newExtraScores);
  };

  return (
    <ScorePanel>
      <List subheader={<ListSubheader>Testu rezultāti</ListSubheader>}>
        {Object.entries(testScores).map(([lessonId, score]) => (
          <ListItem key={lessonId}>
            <ListItemText primary={lessonId} />
            <ListItemSecondaryAction>{score}</ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <List subheader={<ListSubheader>Papildrezultāti</ListSubheader>}>
        <AddScoreRow saveScore={onSave} />
        {Object.entries(extraScores).map(([key, score]) => (
          <ScoreRow
            key={key}
            scoreKey={key}
            score={score}
            saveScore={onSave}
            deleteScore={onDelete}
          />
        ))}
      </List>
    </ScorePanel>
  );
};

export const AdminSchoolCupTeam = ({ seasonId, team, deleteTeam }) => {
  const {
    members,
    areMembersReady,
    loadMembers,
    results,
    areResultsReady,
    loadResults,
    saveScore,
    isBusy,
    deleteMember,
  } = useSchoolCup();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const isReady = areMembersReady && areResultsReady;
  const onExpand = (seasonId, teamId) => {
    loadMembers(seasonId, teamId);
    loadResults(seasonId, teamId);
  };

  return (
    <TeamAccordion
      defaultExpanded={false}
      onChange={(_, isExpanded) =>
        isExpanded && !areMembersReady && onExpand(seasonId, team.id)
      }
    >
      <AccordionSummary expandIcon={<MdExpandMore />}>
        <Body>{team.name}</Body>
        {isBusy && <CircularProgress size={24} />}
      </AccordionSummary>
      <AccordionDetails>
        {isReady ? (
          <StyledTeamDetails>
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<MdDelete />}
              onClick={() => setShowDeleteConfirm(true)}
            >
              Dzēst komandu
            </Button>
            <Dialog
              open={showDeleteConfirm}
              onClose={() => setShowDeleteConfirm(false)}
            >
              <DialogTitle>{"Tiešām dzēst komandu?"}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Šī darbība ir neatgriezeniska!
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setShowDeleteConfirm(false)}
                  color="primary"
                >
                  Atcelt
                </Button>
                <Button
                  onClick={() => {
                    setShowDeleteConfirm(false);
                    deleteTeam(seasonId, team.id);
                  }}
                  color="secondary"
                >
                  Dzēst
                </Button>
              </DialogActions>
            </Dialog>
            <TeamMembers
              members={members}
              isCaptain={true}
              deleteMember={(id) => deleteMember(seasonId, id)}
            />
            <TeamResults
              results={results}
              saveScore={(extraScores) =>
                saveScore(seasonId, team.id, extraScores)
              }
            />
          </StyledTeamDetails>
        ) : (
          <LinearProgress />
        )}
      </AccordionDetails>
    </TeamAccordion>
  );
};

export const AdminSchoolCup = ({ seasonId }) => {
  const { teams, areTeamsReady, loadTeams, deleteTeam } = useSchoolCup();

  return (
    <StyledAccordion
      defaultExpanded={false}
      onChange={(_, isExpanded) =>
        isExpanded && !areTeamsReady && loadTeams(seasonId)
      }
    >
      <AccordionSummary expandIcon={<MdExpandMore />}>
        <SectionHeading>
          Komandas {areTeamsReady ? `(${teams.length})` : ""}
        </SectionHeading>
      </AccordionSummary>
      <StyledAccordionDetails>
        {areTeamsReady ? (
          teams.map((team) => (
            <AdminSchoolCupTeam
              key={team.id}
              seasonId={seasonId}
              team={team}
              deleteTeam={deleteTeam}
            />
          ))
        ) : (
          <LinearProgress />
        )}
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};
