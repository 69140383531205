import { Button, Paper } from "@material-ui/core";
import React from "react";
import { MdAdd, MdFileDownload } from "react-icons/md";
import styled from "styled-components";
import { useSeasons } from "../hooks/useSeasons";
import { useUsers } from "../hooks/useUsers";
import { AdminSeason } from "./AdminSeason";

const Panel = styled(Paper)`
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-evenly;
`;

export const AdminDashboard = () => {
  const { seasons, isBusy, saveSeason, deleteSeason, addSeason } = useSeasons({
    showAll: true,
  });

  const { downloadUsersCSV } = useUsers();

  return (
    <>
      <Panel variant="outlined">
        <Button
          variant="contained"
          size="small"
          startIcon={<MdAdd />}
          onClick={() => addSeason()}
        >
          Pievienot sezonu
        </Button>
        <Button
          variant="contained"
          size="small"
          startIcon={<MdFileDownload />}
          onClick={() => downloadUsersCSV()}
        >
          Lejuplādēt lietotāju datus (CSV)
        </Button>
      </Panel>
      {seasons.map((season) => (
        <AdminSeason
          key={season.id}
          season={season}
          isBusy={isBusy}
          saveSeason={saveSeason}
          deleteSeason={deleteSeason}
        />
      ))}
    </>
  );
};
