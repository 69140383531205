import { useEffect, useState, useCallback } from "react";
import { useFirestore } from "./useFirestore";
import { useSnackbar } from "./useSnackbar";

export const useLessonApplicants = (lessonId) => {
  const { getDocs, where } = useFirestore();
  const { enqueueSnackbar } = useSnackbar();
  const [lessonApplicants, setLessonApplicants] = useState([]);
  const [isReady, setIsReady] = useState(false);

  const loadLessonApplicants = useCallback(async (lessonId) => {
    setIsReady(false);
    try {
      const applications = (
        await getDocs(
          `users`,
          where("lessonApplications", "array-contains", lessonId)
        )
      ).docs;
      setLessonApplicants(
        applications.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Kaut kas nogāja greizi! Lūdzu mēģini vēlāk.", {
        variant: "error",
      });
    }
    setIsReady(true);
  }, []);

  useEffect(() => {
    loadLessonApplicants(lessonId);
  }, [lessonId, loadLessonApplicants]);

  return {
    loadLessonApplicants,
    lessonApplicants,
    isReady,
  };
};
