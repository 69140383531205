import { useCallback, useState } from "react";
import { useFirestore } from "./useFirestore";
import { useSnackbar } from "./useSnackbar";
import { useUser } from "./useUser";

export const useSchoolCup = () => {
  const [areTeamsReady, setAreTeamsReady] = useState(false);
  const [areMembersReady, setAreMembersReady] = useState(false);
  const [areResultsReady, setAreResultsReady] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [teams, setTeams] = useState([]);
  const [members, setMembers] = useState([]);
  const [results, setResults] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const { getDocs, where, setDoc, deleteDoc, runTransaction, doc } =
    useFirestore();
  const { user } = useUser();

  const loadTeams = useCallback(async (seasonId) => {
    setAreTeamsReady(false);
    try {
      const teams = (await getDocs(`seasons/${seasonId}/teams`)).docs;
      setTeams(teams.map((doc) => ({ id: doc.id, ...doc.data() })));
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Kaut kas nogāja greizi!", {
        variant: "error",
      });
    } finally {
      setAreTeamsReady(true);
    }
  }, []);

  const loadMembers = useCallback(async (seasonId, teamId) => {
    setAreMembersReady(false);
    try {
      const members = (
        await getDocs(
          `seasons/${seasonId}/teamMembership`,
          where("teamId", "==", teamId)
        )
      ).docs;
      setMembers(
        members.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          isSelf: doc.id === user.email,
        }))
      );
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Kaut kas nogāja greizi!", {
        variant: "error",
      });
    } finally {
      setAreMembersReady(true);
    }
  }, []);

  const loadResults = useCallback(async (seasonId, teamId) => {
    setAreResultsReady(false);
    try {
      const results = (
        await getDocs(`seasons/${seasonId}/teams/${teamId}/results`)
      ).docs;
      setResults(
        results.reduce((acc, doc) => ({ ...acc, [doc.id]: doc.data() }), {})
      );
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Kaut kas nogāja greizi!", {
        variant: "error",
      });
    } finally {
      setAreResultsReady(true);
    }
  }, []);

  const saveScore = useCallback(async (seasonId, teamId, extraScores) => {
    setIsBusy(true);
    try {
      await setDoc(
        `seasons/${seasonId}/teams/${teamId}/results`,
        "extraScores",
        extraScores
      );
      setResults((results) => ({ ...results, extraScores }));
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Kaut kas nogāja greizi!", {
        variant: "error",
      });
    } finally {
      setIsBusy(false);
    }
  }, []);

  const deleteMember = async (seasonId, id) => {
    setIsBusy(true);
    try {
      await deleteDoc(`seasons/${seasonId}/teamMembership`, id);
      enqueueSnackbar("Dalībnieks dzēsts!", {
        variant: "success",
      });
      setMembers((members) => members.filter((member) => member.id !== id));
    } catch (e) {
      console.error(e);
      enqueueSnackbar("Kaut kas nogāja greizi!", {
        variant: "error",
      });
    }
    setIsBusy(false);
  };

  const deleteTeam = async (seasonId, teamId) => {
    setIsBusy(true);
    try {
      const membershipSnapshots = (
        await getDocs(
          `seasons/${seasonId}/teamMembership`,
          where("teamId", "==", teamId)
        )
      ).docs;
      await runTransaction(async (transaction) => {
        transaction.delete(doc(`seasons/${seasonId}/teams`, teamId));
        membershipSnapshots.forEach((ref) =>
          transaction.delete(doc(`seasons/${seasonId}/teamMembership`, ref.id))
        );
      });
      enqueueSnackbar("Komanda dzēsta!", {
        variant: "success",
      });
      setTeams((teams) => teams.filter((team) => team.id !== teamId));
    } catch (e) {
      console.error(e);
      enqueueSnackbar("Kaut kas nogāja greizi!", {
        variant: "error",
      });
    }
    setIsBusy(false);
  };

  return {
    loadTeams,
    teams,
    areTeamsReady,
    loadMembers,
    members,
    areMembersReady,
    loadResults,
    results,
    areResultsReady,
    saveScore,
    isBusy,
    deleteMember,
    deleteTeam,
  };
};
