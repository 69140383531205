import { useFirestore } from "./useFirestore";
import { useSnackbar } from "./useSnackbar";
import { saveAs } from "file-saver";
import { captureException } from "@sentry/minimal";

export const useUsers = () => {
  const { getDocs } = useFirestore();
  const { enqueueSnackbar } = useSnackbar();

  const downloadUsersCSV = async () => {
    try {
      const users = (await getDocs(`users`)).docs;
      const headers = [
        "fullName",
        "email",
        "school",
        "schoolYear",
        "emailsEnabled",
      ];
      const csvHeader = headers.join(",");
      const csvRows = users.map((doc) => {
        const {
          fullName,
          school: { name: schoolName } = {},
          schoolYear,
          emailsEnabled = true,
        } = doc.data();
        return [fullName, doc.id, schoolName, schoolYear, emailsEnabled].join(
          ","
        );
      });
      const csv = [csvHeader, ...csvRows].join("\n");
      const blob = new Blob([csv], { type: "text/plain;charset=utf-8" });
      saveAs(blob, `users-${new Date().toISOString()}.csv`);
    } catch (e) {
      captureException(e);
      enqueueSnackbar("Kaut kas nogāja greizi!", { variant: "error" });
    }
  };

  return {
    downloadUsersCSV,
  };
};
