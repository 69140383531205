import {
  Accordion,
  AccordionDetails,
  Button,
  CircularProgress,
  FormControlLabel,
  Switch,
  TextField,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { lv } from "date-fns/locale";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { MdAdd, MdExpandMore } from "react-icons/md";
import DateFnsUtils from "@date-io/date-fns";
import styled from "styled-components";
import { useLessons } from "../hooks/useLessons";
import {
  AdminLesson,
  ButtonsRow,
  IdChip,
  LessonFormFirstRow,
  LessonFormRow,
  StyledAccordionSummary,
  StyledLessonForm,
} from "./AdminLesson";
import { SectionHeading } from "./Season";
import { Heading3 } from "./Typography";
import { AdminSchoolCup } from "./AdminSchoolCup";

const StyledAccordionDetails = styled(AccordionDetails)`
  display: flex;
  flex-wrap: wrap;
`;

const AccordionWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
`;

const SectionHeadingRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const SeasonFormFirstRow = styled(LessonFormFirstRow)`
  @media screen and (min-width: 900px) {
    grid-template-columns: 75px 1fr 1fr 1fr;
  }
`;

const SeasonForm = ({ season, saveSeason, deleteSeason, isBusy }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: season });

  const onSubmit = (season) => {
    const { id, name, dateStart, dateEnd, isPublic, schoolCupOpen } = season;
    saveSeason(id, {
      name,
      dateStart,
      dateEnd,
      isPublic: !!isPublic,
      schoolCupOpen: !!schoolCupOpen,
    });
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={lv}>
      <StyledLessonForm>
        <SeasonFormFirstRow>
          <TextField
            label="ID"
            variant="outlined"
            disabled={!season.unsaved}
            error={!!errors.id}
            helperText={!!errors.id && "ID ir obligāts un tam jābūt unikālam!"}
            {...register("id", { required: true })}
          />
          <TextField
            label="Nosaukums"
            variant="outlined"
            error={!!errors.name}
            helperText={!!errors.name && "Nosaukums ir obligāts!"}
            {...register("name", { required: true })}
          />
          <FormControlLabel
            control={
              <Controller
                name="isPublic"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Switch
                    checked={!!value}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                )}
              />
            }
            label="Publisks"
          />
          <FormControlLabel
            control={
              <Controller
                name="schoolCupOpen"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Switch
                    checked={!!value}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                )}
              />
            }
            label="Skolu kauss atvērts"
          />
        </SeasonFormFirstRow>
        <LessonFormRow>
          <Controller
            control={control}
            name="dateStart"
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <DatePicker
                label="Sākuma datums"
                inputVariant="outlined"
                value={value}
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="dateEnd"
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <DatePicker
                label="Beigu datums"
                inputVariant="outlined"
                value={value}
                onChange={onChange}
              />
            )}
          />
        </LessonFormRow>
        <ButtonsRow>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSubmit(onSubmit)}
          >
            Saglabāt
          </Button>
          {season.unsaved && (
            <Button variant="contained" onClick={() => deleteSeason(season.id)}>
              Dzēst
            </Button>
          )}
          {isBusy(season.id) && <CircularProgress size={36} />}
        </ButtonsRow>
      </StyledLessonForm>
    </MuiPickersUtilsProvider>
  );
};

export const AdminSeason = ({
  season,
  isBusy: isSeasonBusy,
  saveSeason,
  deleteSeason,
}) => {
  const { id, name, unsaved, isCurrentSeason } = season;
  const [isExpanded, setIsExpanded] = useState(isCurrentSeason || !!unsaved);
  const { lessons, addLesson, saveLesson, deleteLesson, isBusy } = useLessons(
    id,
    { showAll: true }
  );
  return (
    <Accordion expanded={isExpanded}>
      <StyledAccordionSummary
        expandIcon={<MdExpandMore />}
        $unsaved={unsaved}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <IdChip label={id} size="small" variant="outlined" />
        <Heading3>{name}</Heading3>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <SeasonForm
          season={season}
          isBusy={isSeasonBusy}
          saveSeason={saveSeason}
          deleteSeason={deleteSeason}
        />
        <SectionHeadingRow>
          <SectionHeading>Nodarbības</SectionHeading>
          <Button
            variant="contained"
            size="small"
            startIcon={<MdAdd />}
            onClick={() => addLesson()}
          >
            Pievienot nodarbību
          </Button>
        </SectionHeadingRow>
        <AccordionWrapper>
          {lessons.map((lesson) => (
            <AdminLesson
              key={lesson.id}
              lesson={lesson}
              saveLesson={saveLesson}
              deleteLesson={deleteLesson}
              isBusy={isBusy}
            />
          ))}
        </AccordionWrapper>
        <SectionHeadingRow>
          <SectionHeading>Skolu kauss</SectionHeading>
        </SectionHeadingRow>
        <AccordionWrapper>
          <AdminSchoolCup seasonId={id} />
        </AccordionWrapper>
      </StyledAccordionDetails>
    </Accordion>
  );
};
