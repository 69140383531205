import { navigate } from "gatsby";
import React from "react";
import { Layout } from "../components/Layout";
import { AdminDashboard } from "../components/AdminDashboard";
import Seo from "../components/SEO";
import { useAuth } from "../hooks/useAuth";
import { useUser } from "../hooks/useUser";
import { LinearProgress } from "@material-ui/core";

const AdminPage = () => {
  const { isAuthenticated } = useAuth();
  const { isReady, user } = useUser();

  React.useEffect(() => {
    if (!isAuthenticated || (isReady && !user.isAdmin)) {
      navigate("/ienakt");
    }
  }, [isAuthenticated, isReady, user]);

  if (!isAuthenticated || !isReady || (isReady && !user.isAdmin)) {
    return (
      <Layout>
        <LinearProgress />
      </Layout>
    );
  }

  return (
    <Layout>
      <Seo title="Administrācijas panelis" />
      <AdminDashboard />
    </Layout>
  );
};

export default AdminPage;
